<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="salesman" @click="bumen_renyuan_zhankai!=false?bumen_renyuan_zhankai=false:''">
        <div class="salesman_tit"></div>
        <div class="salesman_box">
            <span class="salesman_box_btn" @click="dianji_kanban">看板</span>
            <div class="salesman_box_con">
                <div style='height:50%;'>
                    <div class="salesman_box_con_left" v-if="shifou_kexuan">
                        <div class="salesman_box_con_left_fu" @click.stop>
                            <span @click="bumen_renyuan_zhankai=!bumen_renyuan_zhankai">{{zhanshi_name}}<i :class="bumen_renyuan_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></span>
                            <ul v-if="bumen_renyuan_zhankai">
                                <li v-for="(i,index) in bumen_renyuan_list" :key="index" @click="xuanze_zhanshi_name(i)">{{i}}</li>
                            </ul>
                        </div>
                        <el-tooltip class="item" effect="dark" :content="xuanzhong_bumen_name" placement="top">
                            <p class="salesman_box_con_left_xiangqing" @click="dianji_bumen_renyuan">{{xuanzhong_bumen_name}}</p>
                        </el-tooltip>
                    </div>
                    <div class="salesman_box_con_left" v-if="!shifou_kexuan">
                        <div class="salesman_box_con_left_fu">
                            <span>{{zhanshi_name}}</span>
                        </div>
                        <el-tooltip class="item" effect="dark" :content="jichuxinxi.name" placement="top">
                            <p class="salesman_box_con_left_xiangqing">{{jichuxinxi.name}}</p>
                        </el-tooltip>
                    </div>
                    <div class="salesman_box_con_huabu" id='bumen_renyuan_tu1' :style="{width: '58%',height:'90%'}"></div>
                </div>
                <div style='height:50%;'>
                    <div class="salesman_box_con_left">
                        <div class="salesman_box_con_left_fu">
                            <span>产品</span>
                        </div>
                        <el-tooltip class="item" effect="dark" :content="xuanzhong_chanpin_name" placement="top">
                            <p class="salesman_box_con_left_xiangqing" @click="dakai_chanpin">{{xuanzhong_chanpin_name}}</p>
                        </el-tooltip>
                    </div>
                    <div class="salesman_box_con_huabu" id='bumen_renyuan_tu2' :style="{width: '58%',height:'90%'}"></div>
                </div>
                <ul class="tishi_text">
                    <li v-for="(i,index) in tishi_list" :key="index">
                        <p></p>{{i}}
                    </li>
                </ul>
            </div>
        </div>
        <el-dialog title="选择部门" :center="true" :show-close='false' append-to-body :visible.sync="bumen">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="nei_box">
                        <!-- check-strictly -->
                        <el-tree
                            :data="bumen_tree"
                            show-checkbox
                            default-expand-all
                            ref="tree"
                            highlight-current
                            :props="tree_guize"
                            :filter-node-method="filterNode"
                            style="background: #E7E8EB"

                            >
                            <!-- @check='fuxuan' -->
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="bumen_sure">确定</p>
                <p class="quxiao" @click="bumen=false">取消</p>
            </div>
        </el-dialog>
        <!-- <el-dialog title="选择产品" :center="true" :show-close='false' append-to-body :visible.sync="xuanze_chanpin">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="nei_box">
                        <el-tree
                            :data="chanpin_tree"
                            show-checkbox
                            node-key='prod_id'
                            default-expand-all
                            ref="xiaoshou_chanpin_tree"
                            highlight-current
                            :props="tree_guize"
                            check-strictly
                            style="background: #E7E8EB"
                            >
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chanpin_sure">确定</p>
                <p class="quxiao" @click="xuanze_chanpin=false">取消</p>
            </div>
        </el-dialog> -->
        <div class='beijing' v-if='chanpin' @click="chanpin=false">
            <div class="beijing_box" @click.stop>
                <i class="el-icon-error guanbi_xuanze_chanpin" @click="chanpin=false"></i>
                <div class='beijing_box_tit' @click="dakai_chanpin_shu=false">
                    产品分类：
                    <!-- <el-select v-model="xuanze_chanpin_id" clearable @change="xuanze_chanpin_dalei" class='shiyong_biaodan_xiala_kuang' placeholder="请选择">
                        <el-option
                        v-for="item in qiye_chanpin_leixing_list"
                        :key="item.cat_id"
                        :label="item.cat_name"
                        :value="item.cat_id">
                        </el-option>
                    </el-select> -->
                    <div class="beijing_box_tit_div">
                        <p class="beijing_box_tit_p">{{dangqian_chanpin.label}}</p>
                        <div class="beijing_box_tit_tree">
                            <el-tree :data="qiye_chanpin_leixing_tree" :props="defaultProps" @node-click="xuanze_chanpin_dalei"></el-tree>
                        </div>
                    </div>
                    <div class="shiyong_chanpin_dalei_kuang" v-if="chanpin_leixing_tree.length!=0">
                        <p @click.stop="dakai_chanpin_shu=!dakai_chanpin_shu">
                            {{suoxuan_list.map(item=>item.label).join(',')}}
                            <!-- <span v-for="(item,index) in suoxuan_list" :key="item.id" v-if="index<3">{{item.label}}</span>
                            {{suoxuan_list.length>3?'...':''}} -->
                        </p>
                        <div class="rolekanban_box_tit_right_box_con" v-show="dakai_chanpin_shu" @click.stop>
                            <el-input
                                placeholder="请输入搜索内容"
                                class="kehu_sousuo_renyuan"
                                v-model="chanpin_text">
                            </el-input>

                                <!-- :filter-node-method="filterNode"  获取当前node -->
                                <!-- default-expand-all  是否展开字节 -->
                            <el-tree
                                class="filter-tree kehu_tree"
                                :data="chanpin_leixing_tree"
                                :props="tree_guize"
                                ref="kehu_chanpin_tree"
                                show-checkbox
                                node-key='id'
                                highlight-current
                                @check-change="dianji_chanpin_shu"
                                :filter-node-method="chanpin_tree_guolv"
                                >
                                <!-- @check='fuxuan' -->
                            </el-tree>
                        </div>
                    </div>
                    搜索：
                    <p class="shiyong_sousuo_chanpin">
                        <i class="el-icon-search"></i>
                        <input v-model="sousuo_chanpin_text" @keyup.enter="chanpin_sousuo()" type="text">
                        <i @click="sousuo_chanpin_text='',chanpin_sousuo()" class='el-icon-circle-close'></i>
                    </p>
                    <span class="shiyong_chanpin_queding" @click="dianji_xuanze_chanpin_baocun()">确定</span>
                </div>
                <ul class="mingxi_chanpin_yangshi">
                    <li v-for='(cp,ind) in dangqian_mingxi_kuang_chanpin' :key="cp.prod_code">
                        <i @click="shanchu_yixuan_chanpin(cp,ind)" class="el-icon-error"></i>
                        {{cp.prod_name}}
                    </li>
                </ul>
                <div class='beijing_box_table'>
                    <!-- @selection-change="handleSelectionChange"  -->
                    <!-- @current-change="chooseMcaterialChange" -->
                    <el-table :cell-style="liebiao_yangshi" height="500" :header-cell-style="biaotou_yangshi" :data="chanpin_liebiao_list"
                        highlight-current-row
                        ref="Tablea"
                        style="width: 100%"
                        @select = "xuanze_chanpina"
                        @select-all = "xuanze_chanpin_quanxuan"
                        >
                        <el-table-column  type="selection" width="42">
                        </el-table-column>
                        <el-table-column  prop="prod_code" label="产品编号">
                        </el-table-column>
                        <el-table-column  prop="prod_name" label="产品">
                        </el-table-column>
                        <el-table-column prop="cat_name"  label="类型">
                        </el-table-column>
                        <el-table-column  prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column  prop="type" label="型号">
                        </el-table-column>
                        <el-table-column  prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column  prop="price_basic" label="初始价">
                        </el-table-column>
                        <!-- <el-table-column  prop="qty" label="数量">
                        </el-table-column> -->
                        <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                        </el-table-column>
                        <el-table-column  prop="price_sale" label="售价" width="80">
                        </el-table-column> -->
                        <el-table-column  prop="disc_rate" label="调价率">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="shaixuan_ren">
            <div class="tiao_bumen2">
                <div class="xuanze_left">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" v-model="sousuo_text" @keyup='shaixuan_sousuo'>
                        <!--  @keyup="keyu" -->
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(a,idx) in mianbao_chaosong_list" :key='idx'  @click="mianbao_chaosong_dian(a,idx)">
                            <p :class="mianbao_chaosong_list.length-1==idx?'p1':'p2'">{{a.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan" v-if="chaosong_qiye_renyuan_list.length!=0">
                        <img @click="dianji_chaosong_quanxuan" :src="renyuan_chaosong_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_bumen_list" :key="idx" @click="dianji_chaosong_bumen(a)">
                                    <!-- <img class="img2" @click.stop='chaosong_bumen(a)' :src="a.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt=""> -->
                                    <p style="margin-left:0.16rem;">{{a.dept_name}}</p>
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_renyuan_list" :key="idx" style='justify-content:left;margin-left:-0.07rem;'>
                                    <img class="img2" @click="dianji_chaosong_xuanren(a)" :src="a.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="xuanze_right">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_renyuan_list_xuan" :key="idx">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chaosong_renyuan_sure">确定</p>
                <p class="quxiao" @click="shaixuan_ren=false;chaosong_renyuan_list_xuan=[]">取消</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { query_all_module_sale_userid, query_prod_info_list_of_cats, query_ent_prod_cat_list_tree_of_parent, query_ent_prod_cat_list_tree, query_all_prod_list_of_category, get_prod_list_like, query_category_info_list, query_prod_info_list, query_all_module_read_power_list, no_sale_prod_list, query_ent_prod_list_tree, supply_as_dept_aim_data, query_user_info, get_staff_list_like, query_prod_tree_sale_rule, query_ent_dept_list, query_dept_staff_list, customer_manager_main_interface, query_mng_staff_list, generate_sale_mng_bar_chart, query_ent_dept_list_aim } from '../../api/api.js'
export default {
  name: 'salesman',
  data () {
    return {
      tit_list: [],
      dangqian: '0',
      bumen_renyuan_list: ['部门', '人员'],
      bumen_renyuan_zhankai: false, // 点击部门人员展开
      zhanshi_name: '部门',
      zhuzhuang_tu1: {},
      zhuzhuang_tu2: {},
      huise: [],
      zise: [],
      hongxian: [],
      chengse: [],
      lanse: [],
      shenlan: [],
      yinglv: [],
      huise2: [],
      zise2: [],
      hongxian2: [],
      chengse2: [],
      lanse2: [],
      shenlan2: [],
      yinglv2: [],
      xuanzhong_bumen_name: '',
      xuanzhong_chanpin_name: '',
      bumen: false,
      tishi_list: [
        '目标', '漏斗', '折算漏斗', '合同', '发票', '回款', '时间进度线', '预警线'
      ],
      bumen: false,
      bumen_tree: [],
      tree_guize: {
        children: 'children',
        label: 'label'
      },
      chanpin_tree: [],
      sousuo_text: '',
      mianbao_chaosong_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      renyuan_chaosong_quanxuan: false,
      chaosong_renyuan_list_xuan: [],
      chaosong_qiye_renyuan_list: [],
      chaosong_qiye_bumen_list: [],
      shaixuan_ren: false,
      xian_num: '0',
      danwei: '元',
      xian_num2: '0',
      danwei2: '元',
      xuanze_chanpin: false,
      shifou_kexuan: false,
      jichuxinxi: '',
      xuanzhong_bumen_id: '',
      qiye_quanbu: false,

      // 2020/6/10产品更改样式
      xuanze_chanpin_id: '',
      chanpin_liebiao_list: [],
      chanpin_xuanze: '',
      sousuo_chanpin_text: '',
      qiye_chanpin_leixing_list: [],
      chanpin: false,
      buneng_xuan_chanpin: [],
      huixian_zhi: '',
      huixian_zhi2: '',
      chanpin_leixing_tree: [],
      dakai_chanpin_shu: false,
      suoxuan_list: [], // 产品大类所选
      chanpin_text: '',
      dangqian_chanpin: {
        label: '',
        id: ''
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      qiye_chanpin_leixing_tree: [],
      dangqian_mingxi_kuang_chanpin: []
    }
  },
  mounted () {
    // this.canver()
  },
  created () {
    this.jichu()
    this.mianbao_chaosong_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  watch: {
    shaixuan_ren () {
      if (!this.shaixuan_ren) {
        this.chaosong_renyuan_list_xuan = []
        this.chaosong_qiye_renyuan_list = []
        this.renyuan_chaosong_quanxuan = false
        this.mianbao_chaosong_list = [
          {
            name: JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name,
            dept_id: '0'
          }
        ]
      }
    },
    chanpin () {
      if (!this.chanpin) {
        this.dangqian_chanpin = {
          label: '',
          id: ''
        }
        this.sousuo_chanpin_text = ''
        this.chanpin_xuanze = ''
        this.chanpin_liebiao_list = []
      }
    },
    xuanze_chanpin_id () {
      this.suoxuan_list = []
      if (this.dangqian_chanpin.id.length != 0) {
        this.chanpin_dalei_shu(this.dangqian_chanpin.id)
      } else {
        this.suoxuan_list = []
      }
    },
    chanpin_text (val) {
      this.$refs.kehu_chanpin_tree.filter(val)
    }
  },
  methods: {
    jichu () {
      this.jichuxinxi = this.$jichuxinxi()
      // let obj={
      //     amt_aim:'2000',//本年目标销售额
      //     amt_con:'1800',//实际金额（合同）
      //     amt_inv:'1300',//发票
      //     amt_rec:'5000',//回款
      //     amt_funn:'3100',//..漏斗金额
      //     amt_funn_disc:'1300',//..漏斗折后金额
      //     amt_aim_current:'600',//时间进度线
      // }
      // let shuzu=[]
      // shuzu.push(Number(obj.amt_aim))
      // shuzu.push(Number(obj.amt_con))
      // shuzu.push(Number(obj.amt_inv))
      // shuzu.push(Number(obj.amt_rec))
      // shuzu.push(Number(obj.amt_funn))
      // shuzu.push(Number(obj.amt_funn_disc))
      // shuzu.push(Number(obj.amt_aim_current))
      // let zuida_zhi=shuzu.reduce((num1, num2) => {
      //     return num1 > num2 ? num1 : num2
      // })
      // console.log(zuida_zhi)
      // setTimeout(() => {
      //     this.jisuan(obj,zuida_zhi*0.01)
      // }, 0)

      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          query_all_module_read_power_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id
            }
          }).then(kan_zong => {
            console.log(kan_zong)
            if (kan_zong.data.code == 200) {
              const zong = JSON.parse(kan_zong.data.body.data)
              if (res.data.body.dept_manager == 1 || res.data.body.staff_level == 1 || zong.length != 0) {
                this.tuxing_jiekou('dept', null)
                this.shifou_kexuan = true
              } else {
                this.huoqu_all_mokuai_xiaoshou()
              }
              if (!this.qiye_quanbu) {
                if (res.data.body.staff_level == 1 || res.data.body.staff_level == 2 || zong.length != 0) {
                  this.qiye_quanbu = true
                  this.tuxing_jiekou2('prod', null)
                } else {
                  this.huoqu_all_mokuai_xiaoshou()
                }
              }
              // if(this.qiye_quanbu){
              //     this.buneng_xuan_chanpin=[]
              // }else{

              // }
            } else if (kan_zong.data.code == 500) {}
          })
        }
      })
    },
    // 获取是否有销售权限
    huoqu_all_mokuai_xiaoshou () {
      query_all_module_sale_userid({
        data: {
          ent_id: this.$ent_id(),
          read_flag: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          // if(date!=null){
          console.log(date)
          let ids = []
          if (date != null && date.staff_ids != undefined) {
            ids = date.staff_ids.split(',')
          }
          console.log(ids)

          ids.forEach(item => {
            if (item == this.$jichuxinxi().user_id) {
              this.qiye_quanbu = true
            }
          })
          if (this.qiye_quanbu || this.shifou_kexuan) {
            this.shifou_kexuan = true
            this.tuxing_jiekou('dept', null)
          } else {
            this.shifou_kexuan = false
            this.zhanshi_name = '人员'
            this.xuanzhong_bumen_id = this.$jichuxinxi().user_id
            this.tuxing_jiekou('staff', this.$jichuxinxi().user_id)
          }
          this.tuxing_jiekou2('prod', null)
          // }
        } else if (res.data.code == 500) {}
      })
    },
    no_xuan_chanpin (date) {
      no_sale_prod_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          // type:'staff',
          staff_ids: this.$jichuxinxi().user_id,
          dept_ids: this.zhanshi_name == '部门' ? (this.xuanzhong_bumen_name.length != 0 ? this.xuanzhong_bumen_id : null) : null
        }
      }).then(ras => {
        console.log(ras)
        if (ras.data.code == 200) {
          const data = JSON.parse(ras.data.body.data)
          const list = []
          for (let i = 0; i < data.length; i++) {
            list.push({
              prod_id: data[i],
              cat_id: data[i]
            })
          }
          this.buneng_xuan_chanpin = list
          console.log(list)
          console.log(date)

          var a = date
          var b = list
          const deal = (arr1, arr2, key) => {
            const arr3 = []
            for (let index = 0; index < arr1.length; index++) {
              const item = arr1[index]
              if (!arr2.some(v => v[key] === item[key])) {
                arr3.push(item)
              }
            }
            return arr3
          }
          console.log(deal(a, b, 'prod_id'))
          this.chanpin_liebiao_list = deal(a, b, 'prod_id')
          console.log(this.chanpin_liebiao_list)

          if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
            this.$nextTick(function () {
              this.chanpin_liebiao_list.forEach(item => {
                this.dangqian_mingxi_kuang_chanpin.forEach(ite => {
                  if (ite.prod_id == item.prod_id) {
                    console.log(item)
                    this.$refs.Tablea.toggleRowSelection(item)
                  }
                })
              })
            })
          }
          this.$forceUpdate()
        } else if (ras.data.code == 500) {}
      })
    },
    // jisuan(obj,xian){
    //     this.zhuzhuang_tu1=obj
    //     this.huise=[]
    //     this.hongxian=['']
    //     this.zise=[]
    //     this.chengse=[]
    //     this.lanse=['']
    //     this.shenlan=['','']
    //     this.yinglv=['','','']
    //     this.xian_num=xian
    //     if(Number(obj.amt_funn)>Number(obj.amt_funn_disc)){
    //         let hui=(Number(obj.amt_funn)-Number(obj.amt_funn_disc)).toFixed(2)
    //         this.huise.push(hui)
    //     }
    //     this.zise.push(obj.amt_funn_disc)
    //     if(Number(obj.amt_aim)>Number(obj.amt_con)){
    //         this.chengse.push(Number(obj.amt_aim)-Number(obj.amt_con)-xian)
    //     }else{
    //         this.chengse.push('0')
    //     }
    //     for(let i=0;i<3;i++){
    //         this.hongxian.push(Number(obj.amt_aim_current)-xian)
    //         this.chengse.push(Number(obj.amt_aim)-xian)
    //     }
    //     console.log(this.chengse)
    //     this.lanse.push(obj.amt_con)
    //     this.shenlan.push(obj.amt_inv)
    //     this.yinglv.push(obj.amt_rec)
    //     console.log(this.huise)
    //     console.log(this.lanse)
    //     console.log(this.hongxian)
    //     this.canver()
    //     if(this.zhanshi_name=='部门'){
    //         this.canver2()
    //     }
    // },
    // 产品大类树
    chanpin_dalei_shu (parent_id) {
      query_ent_prod_cat_list_tree_of_parent({
        data: {
          ent_id: this.$ent_id(),
          parent_id: parent_id,
          user_id: this.$jichuxinxi().user_id,
          dept_ids: this.zhanshi_name == '部门' ? (this.xuanzhong_bumen_name.length != 0 ? this.xuanzhong_bumen_id : null) : null
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          // let tree=[]
          // date.forEach(item=>{
          //     if(item.id==parent_id){
          //         tree=item.children
          //     }
          // })
          this.chanpin_leixing_tree = date
          this.dalei_shu_zhanshi()
        } else if (res.data.code == 500) {}
      })
    },
    // 过滤产品树
    chanpin_tree_guolv (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 产品大类树展示
    dalei_shu_zhanshi () {
      // this.$refs.tree.setCheckedKeys([3]);
      // this.suoxuan_list.splice(index,1)
      const list = this.suoxuan_list.map(item => item)
      console.log(list)
      if (list.length != 0) {
        setTimeout(() => {
          this.$refs.kehu_chanpin_tree.setCheckedKeys(list)
        // this.dongtai_chaxun()
        }, 0)
      }
    },
    // dianji_chanpin_shu(val){
    //     console.log(val)
    //     // this.suoxuan_list.forEach(item=>{
    //     //     if(val.id==item.id){

    //     //     }
    //     // })
    //     let shanchu=false
    //     for(let i=0;i<this.suoxuan_list.length;i++){
    //         if(this.suoxuan_list[i].id==val.id){
    //             this.suoxuan_list.splice(i,1)
    //             shanchu=true
    //             break;
    //         }
    //     }
    //     setTimeout(() => {
    //         if(!shanchu){
    //             this.suoxuan_list.push({
    //                 label:val.label,
    //                 id:val.id,
    //                 type:val.type==1?'3':(val.type==2?'3':'')
    //             })
    //         }
    //     }, 0)
    // },
    dianji_chanpin_shu (val) {
      console.log(val)
      const list = this.$func.getSimpleCheckedNodes(this.$refs.kehu_chanpin_tree.store)
      console.log(list)
      this.suoxuan_list = list
      query_prod_info_list_of_cats({
        data: {
          ent_id: this.$ent_id(),
          cat_ids: list.length != 0 ? list.map(item => item.id).join(',') : this.dangqian_chanpin.id,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.no_xuan_chanpin(date)
        } else if (res.data.code == 500) {}
      })
    },
    tuxing_jiekou (type, id) {
      generate_sale_mng_bar_chart({
        data: {
          ent_id: this.$ent_id(),
          type: type,
          dept_ids: type == 'dept' ? id : null,
          prod_ids: type == 'prod' ? id : null,
          staff_ids: type == 'staff' ? id : null,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          // obj=date
          const obj = {}
          obj.amt_aim = Number(date.amt_aim).toFixed(2)// 本年目标销售额
          obj.amt_con = Number(date.amt_con).toFixed(2)// 实际金额（合同）
          obj.amt_inv = Number(date.amt_inv).toFixed(2)// 发票
          obj.amt_rec = Number(date.amt_rec).toFixed(2)// 回款
          obj.amt_funn = Number(date.amt_funn).toFixed(2)// ..漏斗金额
          obj.amt_funn_disc = Number(date.amt_funn_disc).toFixed(2)// ..漏斗折后金额
          obj.amt_aim_current = Number(date.amt_aim_current).toFixed(2)// 时间进度线
          const shuzu = []
          shuzu.push(obj.amt_aim != undefined ? Number(obj.amt_aim) : 0)
          shuzu.push(obj.amt_con != undefined ? Number(obj.amt_con) : 0)
          shuzu.push(obj.amt_inv != undefined ? Number(obj.amt_inv) : 0)
          shuzu.push(obj.amt_rec != undefined ? Number(obj.amt_rec) : 0)
          shuzu.push(obj.amt_funn != undefined ? Number(obj.amt_funn) : 0)
          shuzu.push(obj.amt_funn_disc != undefined ? Number(obj.amt_funn_disc) : 0)
          shuzu.push(obj.amt_aim_current != undefined ? Number(obj.amt_aim_current) : 0)
          console.log(shuzu)
          let zuida_zhi = shuzu.reduce((num1, num2) => {
            return num1 > num2 ? num1 : num2
          })
          console.log(zuida_zhi)
          this.danwei = '元'
          if (Number(zuida_zhi) >= 10000) {
            this.danwei = '万元'
            obj.amt_aim = (Number(obj.amt_aim) / 10000).toFixed(2)// 本年目标销售额
            obj.amt_con = (Number(obj.amt_con) / 10000).toFixed(2)// 实际金额（合同）
            obj.amt_inv = (Number(obj.amt_inv) / 10000).toFixed(2)// 发票
            obj.amt_rec = (Number(obj.amt_rec) / 10000).toFixed(2)// 回款
            obj.amt_funn = (Number(obj.amt_funn) / 10000).toFixed(2)// ..漏斗金额
            obj.amt_funn_disc = (Number(obj.amt_funn_disc) / 10000).toFixed(2)// ..漏斗折后金额
            obj.amt_aim_current = (Number(obj.amt_aim_current) / 10000).toFixed(2)// 时间进度线
            zuida_zhi = Number(zuida_zhi) / 10000
          }
          // this.jisuan(obj,zuida_zhi*0.01)
          this.zhuzhuang_tu1 = obj
          this.huise = []
          this.hongxian = ['']
          this.zise = []
          this.chengse = []
          this.lanse = ['']
          this.shenlan = ['', '']
          this.yinglv = ['', '', '']
          this.xian_num = zuida_zhi * 0.01
          if (Number(obj.amt_funn) > Number(obj.amt_funn_disc)) {
            // let hui=(Number(obj.amt_funn)-Number(obj.amt_funn_disc)).toFixed(2)
            const hui = (Number(obj.amt_funn) - Number(obj.amt_funn_disc)).toFixed(2)
            this.huise.push(hui)
            // +Number(obj.amt_funn_disc))
            this.huixian_zhi = Number(obj.amt_funn).toFixed(2)
          } else {
            this.huise.push(0)
            this.huixian_zhi = Number(obj.amt_funn_disc).toFixed(2)
          }
          this.zise.push(obj.amt_funn_disc)

          if (Number(obj.amt_aim) > Number(obj.amt_con)) {
            this.chengse.push((Number(obj.amt_aim) - Number(obj.amt_con) - Number(zuida_zhi * 0.01)).toFixed(2))
          } else {
            this.chengse.push('0')
          }
          for (let i = 0; i < 3; i++) {
            // -Number(zuida_zhi*0.01)  2020/12/4需要删除
            this.hongxian.push((Number(obj.amt_aim_current)).toFixed(2))
            this.chengse.push((Number(obj.amt_aim) - Number(zuida_zhi * 0.01)).toFixed(2))
          }
          console.log(this.chengse)
          this.lanse.push(obj.amt_con)
          this.shenlan.push(obj.amt_inv)
          this.yinglv.push(obj.amt_rec)
          this.canver()
          this.shaixuan_ren = false
          this.bumen = false
        } else if (res.data.code == 500) {}
      })
    },
    tuxing_jiekou2 (type, id, quanxuan) {
      console.log(quanxuan)
      console.log(quanxuan === false)
      console.log(quanxuan === false ? '0' : '1')
      //
      generate_sale_mng_bar_chart({
        data: {
          ent_id: this.$ent_id(),
          type: type,
          dept_ids: this.zhanshi_name == '部门' ? (this.xuanzhong_bumen_name.length != 0 ? this.xuanzhong_bumen_id : null) : null,
          prod_ids: type == 'prod' ? (quanxuan === false ? id : (this.dangqian_chanpin.id.length != 0 ? this.dangqian_chanpin.id : null)) : null,
          staff_ids: this.zhanshi_name == '人员' ? (this.xuanzhong_bumen_name.length != 0 ? this.xuanzhong_bumen_id : null) : null,
          user_id: this.$jichuxinxi().user_id,
          select_all: quanxuan === false ? '0' : '1' // 是否全选产品
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          // obj=date
          const obj = {}
          obj.amt_aim = date.amt_aim != undefined ? Number(date.amt_aim).toFixed(2) : 0// 本年目标销售额
          obj.amt_con = date.amt_con != undefined ? Number(date.amt_con).toFixed(2) : 0// 实际金额（合同）
          obj.amt_inv = date.amt_inv != undefined ? Number(date.amt_inv).toFixed(2) : 0// 发票
          obj.amt_rec = date.amt_rec != undefined ? Number(date.amt_rec).toFixed(2) : 0// 回款
          obj.amt_funn = date.amt_funn != undefined ? Number(date.amt_funn).toFixed(2) : 0// ..漏斗金额
          obj.amt_funn_disc = date.amt_funn_disc != undefined ? Number(date.amt_funn_disc).toFixed(2) : 0// ..漏斗折后金额
          obj.amt_aim_current = date.amt_aim_current != undefined ? Number(date.amt_aim_current).toFixed(2) : 0// 时间进度线
          const shuzu = []
          shuzu.push(Number(obj.amt_aim))
          shuzu.push(Number(obj.amt_con))
          shuzu.push(Number(obj.amt_inv))
          shuzu.push(Number(obj.amt_rec))
          shuzu.push(Number(obj.amt_funn))
          shuzu.push(Number(obj.amt_funn_disc))
          shuzu.push(Number(obj.amt_aim_current))
          console.log(shuzu)
          let zuida_zhi = shuzu.reduce((num1, num2) => {
            return num1 > num2 ? num1 : num2
          })
          console.log(zuida_zhi)
          this.danwei2 = '元'
          if (Number(zuida_zhi) >= 10000) {
            this.danwei2 = '万元'
            obj.amt_aim = (Number(obj.amt_aim) / 10000).toFixed(2)// 本年目标销售额
            obj.amt_con = (Number(obj.amt_con) / 10000).toFixed(2)// 实际金额（合同）
            obj.amt_inv = (Number(obj.amt_inv) / 10000).toFixed(2)// 发票
            obj.amt_rec = (Number(obj.amt_rec) / 10000).toFixed(2)// 回款
            obj.amt_funn = (Number(obj.amt_funn) / 10000).toFixed(2)// ..漏斗金额
            obj.amt_funn_disc = (Number(obj.amt_funn_disc) / 10000).toFixed(2)// ..漏斗折后金额
            obj.amt_aim_current = (Number(obj.amt_aim_current) / 10000).toFixed(2)// 时间进度线
            zuida_zhi = Number(zuida_zhi) / 10000
          }
          // this.jisuan(obj,zuida_zhi*0.01)
          this.zhuzhuang_tu2 = obj
          this.huise2 = []
          this.hongxian2 = ['']
          this.zise2 = []
          this.chengse2 = []
          this.lanse2 = ['']
          this.shenlan2 = ['', '']
          this.yinglv2 = ['', '', '']
          this.xian_num2 = zuida_zhi * 0.01
          if (Number(obj.amt_funn) > Number(obj.amt_funn_disc)) {
            // let hui=(Number(obj.amt_funn)-Number(obj.amt_funn_disc)).toFixed(2)
            const hui = (Number(obj.amt_funn) - Number(obj.amt_funn_disc)).toFixed(2)
            this.huise2.push(hui)
            // +Number(obj.amt_funn_disc))
            this.huixian_zhi2 = Number(obj.amt_funn).toFixed(2)
          } else {
            this.huise2.push(0)
            this.huixian_zhi2 = Number(obj.amt_funn_disc).toFixed(2)
          }
          this.zise2.push(obj.amt_funn_disc)
          if (Number(obj.amt_aim) > Number(obj.amt_con)) {
            this.chengse2.push(Number(obj.amt_aim) - Number(obj.amt_con) - (zuida_zhi * 0.01))
          } else {
            this.chengse2.push('0')
          }
          for (let i = 0; i < 3; i++) {
            // -Number(zuida_zhi*0.01)  2020/12/4需要删除
            this.hongxian2.push((Number(obj.amt_aim_current)).toFixed(2))
            this.chengse2.push((Number(obj.amt_aim) - Number(zuida_zhi * 0.01)).toFixed(2))
          }
          console.log(this.chengse)
          this.lanse2.push(obj.amt_con)
          this.shenlan2.push(obj.amt_inv)
          this.yinglv2.push(obj.amt_rec)
          this.canver2()
          this.xuanze_chanpin = false
        } else if (res.data.code == 500) {}
      })
    },
    canver () {
      var echarts = require('echarts')
      var myChart = echarts.init(document.getElementById('bumen_renyuan_tu1'))
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: ['漏斗', '合同', '发票', '回款'],
          axisLabel: {
            margin: 20
          }
        },
        yAxis: {
          type: 'value',
          name: '单位：' + this.danwei,
          min: 0
        },
        series: [
          {
            data: this.chengse,
            barGap: '-100%',
            type: 'bar',
            stack: 'c',
            barWidth: '80',
            // backgroundStyle: {
            //     color: 'rgba(220, 220, 220, 0)'
            // },
            itemStyle: {
              normal: {
                color: 'rgba(255,180,102,1)'/* 设置bar为隐藏，撑起下面横线 */
              }
            }
          },
          {
            data: this.zise,
            type: 'bar',
            stack: 'a',
            legendHoverLink: false,
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#ab95f2'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'insideTop',
              color: '#4c4a4d',
              formatter: parset => {
                return parset.value
              }
            }
          },
          {
            data: this.huise,
            type: 'bar',
            stack: 'a',
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#e9e9e9'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'top',
              color: '#4c4a4d',
              formatter: parset => {
                return this.huixian_zhi
              }
            }
          },
          {
            data: this.lanse,
            type: 'bar',
            stack: 'a',
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#73d0ef'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'insideTop',
              color: '#4c4a4d',
              formatter: parset => {
                if (parset.value == 0) {
                  return parset.value
                } else if (((Number(parset.value) / Number(this.zhuzhuang_tu1.amt_aim)) * 100).toFixed(2) == 'Infinity') {
                  return '(' + parset.value + '、100.00%)'
                } else {
                  return '(' + parset.value + '、' + ((Number(parset.value) / Number(this.zhuzhuang_tu1.amt_aim)) * 100).toFixed(2) + '%)'
                }
              }
            }
          },
          {
            data: this.shenlan,
            type: 'bar',
            stack: 'a',
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#5596d4'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'insideTop',
              color: '#4c4a4d',
              formatter: parset => {
                if (parset.value == 0) {
                  return parset.value
                } else if (((Number(parset.value) / Number(this.zhuzhuang_tu1.amt_aim)) * 100).toFixed(2) == 'Infinity') {
                  return '(' + parset.value + '、100.00%)'
                } else {
                  return '(' + parset.value + '、' + ((Number(parset.value) / Number(this.zhuzhuang_tu1.amt_aim)) * 100).toFixed(2) + '%)'
                }
              }
            }
          },
          {
            data: this.yinglv,
            legendHoverLink: true,
            type: 'bar',
            stack: 'a',
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#cae024'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'insideTop',
              color: '#4c4a4d',
              formatter: parset => {
                if (parset.value == 0) {
                  return parset.value
                } else if (((Number(parset.value) / Number(this.zhuzhuang_tu1.amt_aim)) * 100).toFixed(2) == 'Infinity') {
                  return '(' + parset.value + '、100.00%)'
                } else {
                  return '(' + parset.value + '、' + ((Number(parset.value) / Number(this.zhuzhuang_tu1.amt_aim)) * 100).toFixed(2) + '%)'
                }
              }
            }
            // cae024
          },
          {
            data: this.hongxian,
            barGap: '-100%',
            type: 'bar',
            stack: 'b',
            barWidth: '80',
            // backgroundStyle: {
            //     color: 'rgba(220, 220, 220, 0)'
            // },
            itemStyle: {
              normal: {
                color: 'rgba(0,0,0,0)'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'top',
              color: '#4c4a4d',
              formatter: parset => {
                if (Number(parset.value) <= 0) {

                } else {
                  return '(' + parset.value + '、' + (((Number(parset.value) / Number(this.zhuzhuang_tu1.amt_aim)) * 100).toFixed(2) != 'Infinity' ? ((Number(parset.value) / Number(this.zhuzhuang_tu1.amt_aim)) * 100).toFixed(2) : '100.00') + '%)'
                }
              }
            }
          },
          {
            data: ['', this.xian_num, this.xian_num, this.xian_num],
            barGap: '-100%',
            type: 'bar',
            stack: 'b',
            // label: {
            //     show: true,
            //     position: 'top',
            //     color:'#4c4a4d',
            //     formatter:(parset=>{
            //         if(parset.name=='合同'){
            //             return '('+parset.value+','+((Number(this.zhuzhuang_tu1.value)/Number(this.zhuzhuang_tu1.amt_aim))*100).toFixed(2)+'%)'
            //         }else if(parset.name=='发票'){amt_aiim_current
            //             return '('+parset.value+','+((Number(parset.value)/Number(this.zhuzhuang_tu1.amt_aim))*100).toFixed(2)+'%)'
            //         }else if(parset.name=='回款'){
            //             return '('+parset.value+','+((Number(parset.value)/Number(this.zhuzhuang_tu1.amt_aim))*100).toFixed(2)+'%)'
            //         }
            //     })
            // },
            barWidth: '80',
            itemStyle: {
              normal: {
                color: 'rgba(249,108,108,1)'/* 设置bar为隐藏，撑起下面横线 */
              }
            }
          },
          {
            data: [this.xian_num, this.xian_num, this.xian_num, this.xian_num],
            barGap: '-100%',
            type: 'bar',
            stack: 'c',
            barWidth: '80',
            label: {
              show: true,
              position: 'top',
              color: '#4c4a4d',
              formatter: parset => {
                if (parset.name == '合同') {
                  return Number(this.zhuzhuang_tu1.amt_aim).toFixed(2)
                } else if (parset.name == '发票') {
                  return Number(this.zhuzhuang_tu1.amt_aim).toFixed(2)
                } else if (parset.name == '回款') {
                  return Number(this.zhuzhuang_tu1.amt_aim).toFixed(2)
                } else if (parset.name == '漏斗') {
                  if (Number(this.zhuzhuang_tu1.amt_aim) > Number(this.zhuzhuang_tu1.amt_con)) {
                    return (Number(this.zhuzhuang_tu1.amt_aim) - Number(this.zhuzhuang_tu1.amt_con)).toFixed(2)
                  } else {
                    return 0
                  }
                }
              }
            },
            itemStyle: {
              normal: {
                color: 'rgba(255,180,102,1)'/* 设置bar为隐藏，撑起下面横线 */
              }
            }
          }
          // barGap: '-100%',//添加此配置项即为重叠效果
        ]
      })
    },
    canver2 () {
      var echarts = require('echarts')
      var myChart2 = echarts.init(document.getElementById('bumen_renyuan_tu2'))
      myChart2.setOption({
        xAxis: {
          type: 'category',
          data: ['漏斗', '合同', '发票', '回款'],
          axisLabel: {
            margin: 20
          }
        },
        yAxis: {
          type: 'value',
          name: '单位：' + this.danwei2,
          min: 0
        },
        series: [
          {
            data: this.zhanshi_name != '人员' ? this.chengse2 : [],
            barGap: '-100%',
            type: 'bar',
            stack: 'c',
            barWidth: '80',
            // backgroundStyle: {
            //     color: 'rgba(220, 220, 220, 0)'
            // },
            itemStyle: {
              normal: {
                color: 'rgba(255,180,102,1)'/* 设置bar为隐藏，撑起下面横线 */
              }
            }
          },
          {
            data: this.zise2,
            type: 'bar',
            stack: 'a',
            legendHoverLink: false,
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#ab95f2'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'insideTop',
              color: '#4c4a4d',
              formatter: parset => {
                return parset.value
              }
            }
          },
          {
            data: this.huise2,
            type: 'bar',
            stack: 'a',
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#e9e9e9'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'top',
              color: '#4c4a4d',
              formatter: parset => {
                return this.huixian_zhi2
              }
            }
          },
          {
            data: this.lanse2,
            type: 'bar',
            stack: 'a',
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#73d0ef'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'insideTop',
              color: '#4c4a4d',
              formatter: parset => {
                if (parset.value == 0) {
                  return parset.value
                } else if (((Number(parset.value) / Number(this.zhuzhuang_tu2.amt_aim)) * 100).toFixed(2) == 'Infinity') {
                  return this.zhanshi_name != '人员' ? '(' + parset.value + '、100.00%)' : parset.value
                } else {
                  return this.zhanshi_name != '人员' ? '(' + parset.value + '、' + ((Number(parset.value) / Number(this.zhuzhuang_tu2.amt_aim)) * 100).toFixed(2) + '%)' : parset.value
                }
                // return this.zhanshi_name!='人员'?'('+parset.value+','+(((Number(parset.value)/Number(this.zhuzhuang_tu2.amt_aim))*100).toFixed(2)=='Infinity'?'100.00':((Number(parset.value)/Number(this.zhuzhuang_tu2.amt_aim))*100).toFixed(2))+'%)':parset.value
              }
            }
          },
          {
            data: this.shenlan2,
            type: 'bar',
            stack: 'a',
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#5596d4'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'insideTop',
              color: '#4c4a4d',
              formatter: parset => {
                if (parset.value == 0) {
                  return parset.value
                } else if (((Number(parset.value) / Number(this.zhuzhuang_tu2.amt_aim)) * 100).toFixed(2) == 'Infinity') {
                  return this.zhanshi_name != '人员' ? '(' + parset.value + '、100.00%)' : parset.value
                } else {
                  return this.zhanshi_name != '人员' ? '(' + parset.value + '、' + ((Number(parset.value) / Number(this.zhuzhuang_tu2.amt_aim)) * 100).toFixed(2) + '%)' : parset.value
                }
                // return  this.zhanshi_name!='人员'?'('+parset.value+','+(((Number(parset.value)/Number(this.zhuzhuang_tu2.amt_aim))*100).toFixed(2)=='Infinity'?'100.00':((Number(parset.value)/Number(this.zhuzhuang_tu2.amt_aim))*100).toFixed(2))+'%)':parset.value
              }
            }
          },
          {
            data: this.yinglv2,
            legendHoverLink: true,
            type: 'bar',
            stack: 'a',
            barWidth: '80',
            itemStyle: {
              normal: {
                color: '#cae024'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'insideTop',
              color: '#4c4a4d',
              formatter: parset => {
                if (parset.value == 0) {
                  return parset.value
                } else if (((Number(parset.value) / Number(this.zhuzhuang_tu2.amt_aim)) * 100).toFixed(2) == 'Infinity') {
                  return this.zhanshi_name != '人员' ? '(' + parset.value + '、100.00%)' : parset.value
                } else {
                  return this.zhanshi_name != '人员' ? '(' + parset.value + '、' + ((Number(parset.value) / Number(this.zhuzhuang_tu2.amt_aim)) * 100).toFixed(2) + '%)' : parset.value
                }
              }
            }
            // cae024
          },
          {
            data: this.zhanshi_name != '人员' ? [this.xian_num2, this.xian_num2, this.xian_num2, this.xian_num2] : [],
            barGap: '-100%',
            type: 'bar',
            stack: 'c',
            barWidth: '80',
            label: {
              show: true,
              position: 'top',
              color: '#4c4a4d',
              formatter: parset => {
                if (parset.name == '合同') {
                  return Number(this.zhuzhuang_tu2.amt_aim).toFixed(2)
                } else if (parset.name == '发票') {
                  return Number(this.zhuzhuang_tu2.amt_aim).toFixed(2)
                } else if (parset.name == '回款') {
                  return Number(this.zhuzhuang_tu2.amt_aim).toFixed(2)
                } else if (parset.name == '漏斗') {
                  if (Number(this.zhuzhuang_tu2.amt_aim) > Number(this.zhuzhuang_tu2.amt_con)) {
                    return (Number(this.zhuzhuang_tu2.amt_aim) - Number(this.zhuzhuang_tu2.amt_con)).toFixed(2)
                  } else {
                    return 0
                  }
                }
              }
            },
            itemStyle: {
              normal: {
                color: 'rgba(255,180,102,1)'/* 设置bar为隐藏，撑起下面横线 */
              }
            }
          },
          // barGap: '-100%',//添加此配置项即为重叠效果
          {
            data: this.zhanshi_name != '人员' ? this.hongxian2 : [],
            barGap: '-100%',
            type: 'bar',
            stack: 'b',
            barWidth: '80',
            // backgroundStyle: {
            //     color: 'rgba(220, 220, 220, 0)'
            // },
            itemStyle: {
              normal: {
                color: 'rgba(0,0,0,0)'/* 设置bar为隐藏，撑起下面横线 */
              }
            },
            label: {
              show: true,
              position: 'top',
              color: '#4c4a4d',
              // formatter:(parset=>{
              //     return '('+parset.value+','+(((Number(parset.value)/Number(this.zhuzhuang_tu2.amt_aim))*100).toFixed(2)=='Infinity'?'100.00':((Number(parset.value)/Number(this.zhuzhuang_tu2.amt_aim))*100).toFixed(2))+'%)'
              // })
              formatter: parset => {
                if (Number(parset.value) <= 0) {

                } else {
                  return '(' + parset.value + '、' + (((Number(parset.value) / Number(this.zhuzhuang_tu2.amt_aim)) * 100).toFixed(2) != 'Infinity' ? ((Number(parset.value) / Number(this.zhuzhuang_tu2.amt_aim)) * 100).toFixed(2) : '100.00') + '%)'
                }
              }
            }
          },
          {
            data: this.zhanshi_name != '人员' ? ['', this.xian_num2, this.xian_num2, this.xian_num2] : [],
            barGap: '-100%',
            type: 'bar',
            stack: 'b',
            // label: {
            //     show: true,
            //     position: 'top',
            //     color:'#4c4a4d',
            //     formatter:(parset=>{
            //         if(parset.name=='合同'){
            //             return (Number(this.zhuzhuang_tu2.amt_aim_current)-Number(this.xian_num2)).toFixed(2)
            //         }else if(parset.name=='发票'){
            //             return (Number(this.zhuzhuang_tu2.amt_aim_current)-Number(this.xian_num2)).toFixed(2)
            //         }else if(parset.name=='回款'){
            //             return (Number(this.zhuzhuang_tu2.amt_aim_current)-Number(this.xian_num2)).toFixed(2)
            //         }
            //     })
            // },
            barWidth: '80',
            itemStyle: {
              normal: {
                color: 'rgba(249,108,108,1)'/* 设置bar为隐藏，撑起下面横线 */
              }
            }
          }
        ]
      })
    },
    dianji_kanban () {
      this.$router.push('/kanban')
    },
    // 部门人员选择展示
    xuanze_zhanshi_name (i) {
      if (i == '部门' && this.zhanshi_name != i) {
        this.xuanzhong_bumen_name = ''
        this.xuanzhong_bumen_id = ''
        this.xuanzhong_chanpin_name = ''
        this.suoxuan_list = []
        this.tuxing_jiekou('dept', null)
        this.tuxing_jiekou2('prod', null)
      } else if (i == '人员' && this.zhanshi_name != i) {
        this.xuanzhong_bumen_name = ''
        this.xuanzhong_bumen_id = ''
        this.xuanzhong_chanpin_name = ''
        this.suoxuan_list = []
        this.tuxing_jiekou('staff', null)
        this.tuxing_jiekou2('prod', null)
      }
      this.zhanshi_name = i
      this.bumen_renyuan_zhankai = false
    },
    // 复选部门的时候用到的
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    dianji_bumen_renyuan () {
      if (this.zhanshi_name == '部门') {
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.qiye_quanbu ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.bumen_tree = date
          } else if (res.data.code == 10169) {}
        })
        this.bumen = true
      } else if (this.zhanshi_name == '人员') {
        // chaosong_qiye_bumen_list
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.qiye_quanbu ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.shaixuan_ren = true
      }
    },
    // dianji_chanpin(){
    //     // query_prod_tree_sale_rule({data:{
    //     //     ent_id:this.$ent_id(),
    //     //     user_id:this.$jichuxinxi().user_id,
    //     //     active:'1'
    //     // }}).then(res=>{
    //     //     console.log(res)
    //     //     if(res.data.code==200){
    //     //         let date=JSON.parse(res.data.body.data)
    //     //         console.log(date)
    //     //         this.chanpin_tree=date
    //     //     }else if(res.data.code==500){}
    //     // })

    //     // supply_as_dept_aim_data({data:{
    //     //     ent_id:this.$ent_id(),
    //     //     year:(new Date()).getFullYear()+''
    //     // }}).then(res=>{
    //     //     console.log(res)
    //     //     if(res.data.code==200){
    //     //         let date=JSON.parse(res.data.body.data)
    //     //         console.log(date)
    //     //         let list=[]
    //     //         for(let i=1;i<date.length-1;i++){
    //     //             this.$set(date[i],'label',date[i].prod_name)
    //     //             list.push(date[i])
    //     //         }
    //     //         this.chanpin_tree=list
    //     //     }else if(res.data.code==500){}
    //     // })
    //     query_ent_prod_list_tree({data:{
    //         ent_id:this.$ent_id()
    //     }}).then(res=>{
    //         console.log(res)
    //         if(res.data.code==200){
    //             let date=JSON.parse(res.data.body.data)
    //             console.log(date)
    //             no_sale_prod_list({data:{
    //                 ent_id:this.$ent_id(),
    //                 user_id:this.$jichuxinxi().user_id,
    //                 type:this.zhanshi_name=='部门'?'dept':'staff',
    //                 dept_ids:this.zhanshi_name=='部门'?(this.xuanzhong_bumen_id.length!=0?this.xuanzhong_bumen_id:null):null,
    //                 staff_ids:this.zhanshi_name=='部门'?null:this.xuanzhong_bumen_id
    //             }}).then(ras=>{
    //                 this.xuanze_chanpin=true
    //                 console.log(ras)
    //                 let data=JSON.parse(ras.data.body.data)

    //                 this.$func.tickMenuIdFilter2().filter(date,data)
    //                 console.log(date)
    //                 this.chanpin_tree=date
    //             })
    //         }else if(res.data.code==500){}
    //     })
    // },
    bumen_sure () {
      console.log(this.$refs.tree.getCheckedNodes())
      this.xuanzhong_bumen_name = this.$refs.tree.getCheckedNodes().length != 0 ? this.$refs.tree.getCheckedNodes().map(item => item.label).join(',') : ''
      this.xuanzhong_bumen_id = this.$refs.tree.getCheckedNodes().length != 0 ? this.$refs.tree.getCheckedNodes().map(item => item.dept_id).join(',') : ''
      this.xuanzhong_chanpin_name = ''
      this.suoxuan_list = []
      this.tuxing_jiekou('dept', this.$refs.tree.getCheckedNodes().length != 0 ? this.$refs.tree.getCheckedNodes().map(item => item.dept_id).join(',') : null)
      this.tuxing_jiekou2('prod', null)
    },
    chanpin_sure () {
      // if(this.dangqian_xuanze_name=='产品'){
      //     let prod_id=null
      //     if(this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().length!=0){
      //         prod_id=this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().map(item=>item.id).join(',')
      //     }
      // }
      console.log(this.$refs.xiaoshou_chanpin_tree.getCheckedNodes())
      this.xuanzhong_chanpin_name = this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().length != 0 ? this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().map(item => item.label).join(',') : ''
      this.tuxing_jiekou2('prod', this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().length != 0 ? this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().map(item => item.id).join(',') : null)
    },
    shaixuan_sousuo () {
      if (this.sousuo_text.length == 0) {
        this.sousuo_text = null
        this.renyuan_chaosong_quanxuan = false
        this.chaosong_bumen_renyuan_jiekou()
      } else {
        get_staff_list_like({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            staff_name: this.sousuo_text,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10027) {
            const date = JSON.parse(res.data.body.data)
            date.map(item => { this.$set(item, 'zhi', false); this.$set(item, 'name', item.staff_name) })
            this.chaosong_qiye_renyuan_list = date
            this.panduan_youce_zuoce_have()
          } else if (res.data.code == 10028) {
          }
        })
      }
    },
    mianbao_chaosong_dian (i, index) {
      if (this.mianbao_chaosong_list.length - 1 != index && index != 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        this.chaosong_bumen_renyuan_jiekou()
      } else if (index == 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.qiye_quanbu ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.chaosong_qiye_renyuan_list = []
      }
    },
    dianji_chaosong_quanxuan () {
      this.renyuan_chaosong_quanxuan = !this.renyuan_chaosong_quanxuan
      if (this.renyuan_chaosong_quanxuan) {
        // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
        // this.chaosong_qiye_bumen_list.map(item=>{
        //     item.zhi=true
        //     this.$set(item,'name',item.dept_name)
        //     this.$set(item,'ids',item.dept_id)
        //     this.$set(item,'type',1)
        //     this.chaosong_renyuan_list_xuan.push(item)
        // })
        // }
        // for(let i=0;i<this.chaosong_qiye_renyuan_list.length;i++){
        this.chaosong_qiye_renyuan_list.map(item => {
          item.zhi = true
          this.$set(item, 'name', item.staff_name)
          this.$set(item, 'ids', item.staff_id)
          this.$set(item, 'type', 2)
          this.chaosong_renyuan_list_xuan.push(item)
        })
        // }
      } else {
        this.chaosong_renyuan_list_xuan = []
        // this.chaosong_qiye_bumen_list.map(item=>{
        //     item.zhi=false
        // })
        this.chaosong_qiye_renyuan_list.map(item => {
          item.zhi = false
        })
      }
    },
    dianji_chaosong_bumen (i) {
      this.mianbao_chaosong_list.push({ name: i.dept_name, dept_id: i.dept_id })
      this.chaosong_bumen_renyuan_jiekou()
    },
    // 抄送人部门人员列表
    chaosong_bumen_renyuan_jiekou () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          parent_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          // date.map(item=>{this.$set(item,'zhi',false)})
          console.log(date)
          this.chaosong_qiye_bumen_list = date
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              dept_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id,
              active: '1'
            }
          }).then(ras => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data)
              listdata.map(item => { this.$set(item, 'zhi', false) })
              console.log(99999, listdata)
              this.chaosong_qiye_renyuan_list = listdata
              this.panduan_youce_zuoce_have()
              this.panduan_chaosong_quanxuan()
            } else if (ras.data.code == 10150) {
            }
          })
        } else if (res.data.code == 10169) {
        }
      })
    },
    // chaosong_bumen(i){
    //     i.zhi=!i.zhi
    //     if(i.zhi){
    //         this.$set(i,'name',i.dept_name)
    //         this.$set(i,'ids',i.dept_id)
    //         this.$set(i,'type',1)
    //         this.chaosong_renyuan_list_xuan.push(i)
    //     }else{
    //         for(let a=0;a<this.chaosong_renyuan_list_xuan.length;a++){
    //             if(this.chaosong_renyuan_list_xuan[a].ids==i.dept_id){
    //                 this.chaosong_renyuan_list_xuan.splice(a,1)
    //             }
    //         }
    //     }
    //     this.panduan_chaosong_quanxuan()
    // },
    chaosong_renyuan_sure () {
      console.log(this.chaosong_renyuan_list_xuan)
      this.xuanzhong_bumen_name = this.chaosong_renyuan_list_xuan.length != 0 ? this.chaosong_renyuan_list_xuan.map(item => item.staff_name).join(',') : ''
      this.xuanzhong_bumen_id = this.chaosong_renyuan_list_xuan.length != 0 ? this.chaosong_renyuan_list_xuan.map(item => item.staff_id).join(',') : ''
      this.xuanzhong_chanpin_name = ''
      this.suoxuan_list = []
      this.tuxing_jiekou('staff', this.chaosong_renyuan_list_xuan.length != 0 ? this.chaosong_renyuan_list_xuan.map(item => item.staff_id).join(',') : null)
      this.tuxing_jiekou2('prod', null)
    },
    // 判断抄送是否全选
    panduan_chaosong_quanxuan () {
      const list = []
      // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
      //     if(!this.chaosong_qiye_bumen_list[i].zhi){
      //         list.push('111')
      //     }
      // }
      for (let i = 0; i < this.chaosong_qiye_renyuan_list.length; i++) {
        if (!this.chaosong_qiye_renyuan_list[i].zhi) {
          list.push('111')
        }
      }
      if (list.length == 0) {
        this.renyuan_chaosong_quanxuan = true
      } else {
        this.renyuan_chaosong_quanxuan = false
      }
    },
    // 判断右侧有的左侧是否有
    panduan_youce_zuoce_have () {
      for (let i = 0; i < this.chaosong_renyuan_list_xuan.length; i++) {
        // for(let a=0;a<this.chaosong_qiye_bumen_list.length;a++){
        //     if(this.chaosong_renyuan_list_xuan[i].ids==this.chaosong_qiye_bumen_list[a].dept_id){
        //         this.chaosong_qiye_bumen_list[a].zhi=true
        //     }
        // }
        for (let a = 0; a < this.chaosong_qiye_renyuan_list.length; a++) {
          if (this.chaosong_renyuan_list_xuan[i].ids == this.chaosong_qiye_renyuan_list[a].staff_id) {
            this.chaosong_qiye_renyuan_list[a].zhi = true
          }
        }
      }
    },
    dianji_chaosong_xuanren (i) {
      console.log(i)
      i.zhi = !i.zhi
      if (i.zhi) {
        this.$set(i, 'name', i.staff_name)
        this.$set(i, 'ids', i.staff_id)
        this.$set(i, 'type', 2)
        this.chaosong_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.chaosong_renyuan_list_xuan.length; a++) {
          if (this.chaosong_renyuan_list_xuan[a].ids == i.staff_id) {
            this.chaosong_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
      this.panduan_chaosong_quanxuan()
    },
    // 2020、6、10更改产品样式   2020/11/10修改传值
    xuanze_chanpin_dalei (val) {
      console.log(val)
      this.dangqian_chanpin = val
      if (this.dangqian_chanpin.id.length != 0) {
        query_all_prod_list_of_category({
          data: {
            ent_id: this.$ent_id(),
            cat_id: this.dangqian_chanpin.id + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            // let date=JSON.parse(res.data.body.data)
            console.log(this.buneng_xuan_chanpin)
            const date = JSON.parse(res.data.body.data)
            console.log(date)

            // if(this.dangqian_mingxi_kuang_chanpin.length!=0){
            //     this.$nextTick(function () {
            //         this.chanpin_liebiao_list.forEach(item=>{
            //             this.dangqian_mingxi_kuang_chanpin.forEach(ite=>{
            //                 if(ite.prod_id==item.prod_id){
            //                     console.log(item)
            //                     this.$refs.Tablea.toggleRowSelection(item)
            //                 }
            //             })
            //         })
            //     })
            // }
            // this.$forceUpdate()
            this.no_xuan_chanpin(date)
          } else if (res.data.code == 500) {}
        })
      }
    },
    chanpin_sousuo () {
      // sousuo_chanpin_text
      if (this.sousuo_chanpin_text.length != 0) {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: this.sousuo_chanpin_text.length != 0 ? this.sousuo_chanpin_text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            this.no_xuan_chanpin(date)
            // for(let i=0;i<date.length;i++){
            //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
            //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
            //             date.splice(i,1)
            //             i=i-1
            //         }
            //     }
            // }
            // this.chanpin_liebiao_list=date
            // console.log(this.chanpin_liebiao_list)
            this.$forceUpdate()
          } else if (res.data.code == 10032) {}
        })
      } else {
        if (this.dangqian_chanpin.id.length != 0) {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: this.dangqian_chanpin.id + ''
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.no_xuan_chanpin(date)
              // console.log(date)
              // console.log(this.buneng_xuan_chanpin)
              // for(let i=0;i<date.length;i++){
              //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
              //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
              //             date.splice(i,1)
              //             i=i-1
              //         }
              //     }
              // }
              // this.chanpin_liebiao_list=date
              // console.log(this.chanpin_liebiao_list)
              // this.$forceUpdate()
            } else if (res.data.code == 500) {}
          })
        } else {
          this.chanpin_liebiao_list = []
        }
      }
    },
    // 打开产品
    dakai_chanpin () {
      this.chanpin = true
      // query_category_info_list({data:{
      //     ent_id:this.$ent_id(),
      //     user_id:this.$jichuxinxi().user_id,
      //     parent_id:'0',
      //     active:'1'
      // }}).then(res=>{
      //     console.log(4444444,res)
      //     if(res.data.code==10193){
      //         let date=JSON.parse(res.data.body.data)
      //         console.log(date)
      //         var b = this.buneng_xuan_chanpin
      //         console.log(b)
      //         let deal = (arr1, arr2, key) => {
      //             let arr3 = []
      //             for (let index = 0; index < arr1.length; index++) {
      //                 let item = arr1[index]
      //                 if (!arr2.some(v => v[key] === item[key])) {
      //                     arr3.push(item)
      //                 }
      //             }
      //             return arr3
      //         }
      //         this.qiye_chanpin_leixing_list=deal(date,b,'cat_id')
      //     }else if(res.data.code==10194){
      //     }
      // })
      query_ent_prod_cat_list_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_chanpin_leixing_tree = date
        } else if (res.data.code == 500) {}
      })
      query_prod_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          cat_id: '0',
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          this.no_xuan_chanpin(date)
          // console.log(this.buneng_xuan_chanpin)
          // var a = date
          // var b = this.buneng_xuan_chanpin
          // let deal = (arr1, arr2, key) => {
          //     let arr3 = []
          //     for (let index = 0; index < arr1.length; index++) {
          //         let item = arr1[index]
          //         if (!arr2.some(v => v[key] === item[key])) {
          //             arr3.push(item)
          //         }
          //     }
          //     return arr3
          // }
          // console.log(deal(a,b,'prod_id'))
          // this.qiye_chanpin_list=deal(a,b,'prod_id')
          // // buneng_xuan_chanpin
        } else if (res.data.code == 10140) {
        }
      })
      // this.dangqian_mingxi_kuang_chanpin=this.dangqian_xuanze_chanpin
    },
    dianji_xuanze_chanpin_baocun () {
      // console.log(this.chanpin_xuanze[0])
      // if(this.chanpin_xuanze.length!=0){
      // let list=this.chanpin_mingxi_liebiao
      // console.log(list)
      // for(let i=0;i<list.length;i++){
      //     if(list[i].componentName=='product'){
      //         list[i].chanpin_list=this.chanpin_xuanze[0]
      //         list[i].value=list[i].chanpin_list.prod_name
      //     }
      //     if(list[i].componentName=='moneyfield'&&list[i].defaultLable=='单价'){
      //         list[i].value=this.chanpin_xuanze[0].price_unit
      //     }
      // }
      console.log(this.dangqian_mingxi_kuang_chanpin)
      console.log(this.suoxuan_list)
      let quanxuan = false
      if (this.suoxuan_list.length != 0) {
        if (this.chanpin_leixing_tree.length == this.suoxuan_list.length && this.suoxuan_list.length != 0) {
          if (this.dangqian_mingxi_kuang_chanpin.length == this.chanpin_liebiao_list.length) {
            quanxuan = true
          } else {
            quanxuan = false
          }
        } else {
          quanxuan = false
        }
      } else {
        if (this.dangqian_mingxi_kuang_chanpin.length == this.chanpin_liebiao_list.length) {
          quanxuan = true
        } else {
          quanxuan = false
        }
      }
      console.log(quanxuan)
      //
      //
      this.xuanzhong_chanpin_name = this.dangqian_mingxi_kuang_chanpin.length != 0 ? this.dangqian_mingxi_kuang_chanpin.map(item => item.prod_name).join(',') : ''
      this.tuxing_jiekou2('prod', this.dangqian_mingxi_kuang_chanpin.length != 0 ? this.dangqian_mingxi_kuang_chanpin.map(item => item.prod_id).join(',') : null, quanxuan)
      this.chanpin = false
      // }else{
      //     this.$message({
      //         message: '还为选择产品',
      //         type: 'warning'
      //     });
      // }
    },
    liebiao_yangshi (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    biaotou_yangshi (row) {
      return 'color:#1A2533;'
    },
    dianji_xuanzhong_chanpin (val) {
      this.chanpin_xuanze = val
    },

    shanchu_yixuan_chanpin (i, index) {
      this.chanpin_liebiao_list.forEach(ite => {
        if (ite.prod_id == i.prod_id) {
          this.$refs.Tablea.toggleRowSelection(ite)
        }
      })
      this.dangqian_mingxi_kuang_chanpin.splice(index, 1)
    },
    xuanze_chanpina (rows, row) {
      console.log(rows)
      console.log(row)
      const ses = rows.length && rows.indexOf(row) !== -1 // 判断是否打钩
      if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
        if (ses) {
          this.dangqian_mingxi_kuang_chanpin.push(row)
        } else {
          for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == row.prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
            }
          }
        }
      } else {
        this.dangqian_mingxi_kuang_chanpin = rows
      }
      // return rows.length && rows.indexOf(row) !== -1
    },
    xuanze_chanpin_quanxuan (rows, row) {
      console.log(rows)
      if (rows.length != 0) {
        rows.forEach(item => {
          this.dangqian_mingxi_kuang_chanpin.push(item)
        })
        this.dangqian_mingxi_kuang_chanpin = this.$func.Es5duplicate(this.dangqian_mingxi_kuang_chanpin, 'prod_id')
      } else {
        // chanpin_liebiao_list
        // this.dangqian_mingxi_kuang_chanpin
        for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
          // this.chanpin_liebiao_list.forEach(item=>{
          for (let a = 0; a < this.chanpin_liebiao_list.length; a++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == this.chanpin_liebiao_list[a].prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
              break
            }
          }
          // })
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../style/salesman.scss'
</style>
